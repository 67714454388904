import React, { useState, useEffect } from 'react';
import './personaldetail.css';
import userPlaceholder from '../../assets/images/My Selfie Character (head animation).jpeg';
import { MdOutlineCurrencyRupee } from "react-icons/md";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { IoEyeSharp } from "react-icons/io5";
import { FaEyeSlash } from "react-icons/fa";
import Dropdown from '../dropdown/Dropdown';
import axios from 'axios';
import API_URL from '../../utility/api';
import { useNavigate } from 'react-router-dom';
import { useData } from '../../context/AdharDataContext';
import { useTranslation } from 'react-i18next';

function PersonalDetail() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userData, setUserData } = useData();
  const [occupationOptions, setOccupationOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedOccupation, setSelectedOccupation] = useState('');
  const [incomeSources, setIncomeSources] = useState([]);
  const [selectedIncome, setSelectedIncome] = useState('');
  const [showMaritalStatus, setShowMaritalStatus] = useState(false);
  const [maritalStatus, setMaritalStatus] = useState('');
  const [monthlyIncome, setMonthlyIncome] = useState('');
  const [fatherName, setFatherName] = useState('');
  const [showMaidenName, setShowMaidenName] = useState(false);
  const [maidanName, setMaidanName] = useState('');
  const [showMaidenNameIcon, setShowMaidenNameIcon] = useState(false);
  const [confirmMaidanName, setConfirmMaidanName] = useState('');
  const [error, setError] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    const storedResponseData = sessionStorage.getItem('responseData');
    if (storedResponseData) {
      const parsedResponseData = JSON.parse(storedResponseData);
      setUserData(parsedResponseData);
    }
  }, []);

  useEffect(() => {
    const fetchOccupations = async () => {
      try {
        const response = await axios.get(API_URL + '/MasterData/GetOccupationTypeList?langkey=en');
        setOccupationOptions(response.data);
      } catch (error) {
        console.error('Error fetching occupation options:', error);
      }
    };

    const fetchIncomeSources = async () => {
      try {
        const response = await axios.get(API_URL + '/MasterData/GetIncomeSourceType?langkey=en');
        setIncomeSources(response.data);

      } catch (error) {
        console.error('Error fetching income sources:', error);
      }
    };

    fetchOccupations();
    fetchIncomeSources();
  }, []);

  useEffect(() => {
    const isFormValid =
      selectedOccupation &&
      selectedIncome &&
      maritalStatus &&
      monthlyIncome &&
      fatherName &&
      maidanName &&
      confirmMaidanName &&
      maidanName === confirmMaidanName;

    setButtonDisabled(!isFormValid);
  }, [
    selectedOccupation,
    selectedIncome,
    maritalStatus,
    monthlyIncome,
    fatherName,
    maidanName,
    confirmMaidanName,
  ]);

  const handleMaritalStatusChange = (status) => {
    setMaritalStatus(status);
    setShowMaritalStatus(false);
  };

  const handleMonthlyIncomeChange = (e) => {
    const regex = /^[0-9,]*$/;
    if (regex.test(e.target.value) || e.target.value === '') {
      const sanitizedValue = e.target.value.replace(/,/g, '');
      const formattedValue = sanitizedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      setMonthlyIncome(formattedValue);
    }
  };

  const handleFatherNameChange = (e) => {
    const newValue = e.target.value;
    if (newValue === '' || /^[a-zA-Z]+$/.test(newValue)) {
      setFatherName(newValue);
    }
  };

  const handleMaidanNameChange = (e) => {
    const newValue = e.target.value;
    if (newValue === '' || /^[a-zA-Z]+$/.test(newValue)) {
      setMaidanName(newValue);
      setError('');
    }
  };

  const handleConfirmMaidanNameChange = (e) => {
    const newValue = e.target.value;
    if (newValue === '' || /^[a-zA-Z]+$/.test(newValue)) {
      setConfirmMaidanName(newValue);
      setError('');
    }
  };

  const toggleMaidenNameVisibility = () => {
    setShowMaidenName(!showMaidenName);
    setShowMaidenNameIcon(!showMaidenNameIcon);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (maidanName !== confirmMaidanName) {
      setError("Maiden names don't match");
    } else {
      console.log("Form submitted successfully");
    }
  };
  const userDataExists = userData && Object.keys(userData).length > 0;
  if (!userDataExists) {
    console.error('userData is null or empty');
    return; // Handle error or exit early
  }

  const maritalStatusOptions = ['Single', 'Married', 'Divorced', 'Widowed'];
  const genderValue = userData.gender === 'Male' ? 1 : 2;
  const maritalStatusValue = maritalStatusOptions.indexOf(maritalStatus) + 1;
  const parsedMonthlyIncome = parseInt(monthlyIncome.replace(/,/g, ''), 10);

  const handlebutton = async (e) => {
    e.preventDefault();
    if (maidanName !== confirmMaidanName) {
      setError("Maiden names don't match");
      return;
    } else {
      const payload = {
        customerId: userData.customerId,
        firstName: userData.firstName,
        lastName: userData.lastName,
        dateOfBirth: userData.dateOfBirth,
        phoneNumber: userData.phoneNumber,
        panNumber: userData.panNumber,
        aadharNumber: userData.aadharNumber,
        gender: genderValue,
        occupation: selectedOccupation.value,
        incomeSource: selectedIncome.value,
        monthlyIncome: parseInt(monthlyIncome.replace(/,/g, ''), 10),
        maritalStatus: maritalStatusValue,
        fatherName: fatherName,
        motherName: maidanName,
        panCardImage: null,
        profileImage: null,
        signatureImage: null,
        isVideoKycDone: false,
        active: true,
        deleted: false,
      };

      try {
        const response = await axios.post(API_URL + '/Customer/UpdateCustomer', payload);
        console.log('Form submitted successfully', response.data);
        navigate('/address');
      } catch (error) {
        console.error('Error submitting form:', error);
        setError('Error submitting form');
      }
    }
  };

  const dateString = userData && userData.dateOfBirth;
  const date = dateString ? new Date(dateString) : null;
  const formattedDate = date ? `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}` : '';
  const firstName = userData?.firstName || '';
  const lastName = userData?.lastName || '';
  return (
    <div className="pd-container">
      <div className="pd-holder">
        <div className="user-pd">
          <img src={userPlaceholder} alt="userplaceholder" />
          <h3>{firstName} {lastName}</h3>
          <p>DOB: {formattedDate}</p>
          <p>Gender: Male</p>
        </div>
        <div className="pd-form">
          <form onSubmit={handleSubmit}>
            <Dropdown
              title="Select Occupation"
              options={occupationOptions.map((option) => ({ label: option.name, value: option.occupationId?.toString() || '' }))}
              selectedOption={selectedOccupation}
              onSelect={setSelectedOccupation}
            />

            <Dropdown
              title="Select Income Source"
              options={incomeSources.map((source) => ({ label: source.incomeSourceName, value: source.incomeSourceId?.toString() || '' }))}
              selectedOption={selectedIncome}
              onSelect={setSelectedIncome}
            />

            <div className="form-field">
              <div className='input-field'>
                <input
                  type="text"
                  id="monthlyIncome"
                  value={monthlyIncome}
                  placeholder={t('monthlyIncome')}
                  onChange={handleMonthlyIncomeChange}
                  maxLength={15}
                />
                <MdOutlineCurrencyRupee />
              </div>
            </div>

            <Dropdown
              title="Select Marital Status"
              options={maritalStatusOptions.map((status) => ({ label: status, value: status }))}
              selectedOption={maritalStatus}
              onSelect={handleMaritalStatusChange}
            />

            <div className="form-field">
              <div className='input-field'>
                <input
                  type="text"
                  id="fathername"
                  value={fatherName}
                  placeholder={t('fatherName')}
                  onChange={handleFatherNameChange}
                  maxLength={50}
                />
              </div>
            </div>

            <div className='divide-block' />
            <div className="form-field">
              <h3>{t('motherMaidenName')}:</h3>
              <p className='info-note'><IoIosInformationCircleOutline size={16} />{t('rememberForSecurityReason')}</p>
              <div className='input-field'>
                <input
                  type={showMaidenName ? "text" : "password"}
                  id="maidanname"
                  value={maidanName}
                  placeholder={t('motherMaidenName')}
                  onChange={handleMaidanNameChange}
                  maxLength={15}
                />
                {showMaidenNameIcon ? (
                  <IoEyeSharp size={18} color='#003cff' onClick={toggleMaidenNameVisibility} />
                ) : (
                  <FaEyeSlash size={18} color='#003cff' onClick={toggleMaidenNameVisibility} />
                )}
              </div>
            </div>

            <div className="form-field">
              <div className='input-field'>
                <input
                  type={showMaidenName ? "text" : "password"}
                  id="confirmmaidanname"
                  value={confirmMaidanName}
                  placeholder={t('confirmMotherMaidenName')}
                  onChange={handleConfirmMaidanNameChange}
                  maxLength={15}
                />

              </div>
              {maidanName !== confirmMaidanName && (
                <p className="error-message">{t('maidenNamesDontMatch')}</p>
              )}
            </div>


            {error && <p className="error-message">{error}</p>}

            <div className="form-field">
              <button onClick={handlebutton} disabled={buttonDisabled}>{t('proceed')}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PersonalDetail;