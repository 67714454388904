import React, { useState } from 'react';
import './App.css'
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from './i18n';
import Register from './components/register/Register';
import LanguageSwitcher from './components/lang_switch/LanguageSwitcher';
import Welcome from './components/welcome_page/Welcome';
import DocsUpload from './components/upload_documents/DocsUpload';
import ConsentCheck from './components/consent_check/ConsentCheck';
import PersonalDetail from './components/personal_detials/PersonalDetail';
import SetAddress from './components/set_address/SetAddress';
import AddNominee from './components/add_nominee/AddNominee';
import MitcConsent from './components/final_consent/MitcConsent';
import { AdharDataProvider } from './context/AdharDataContext'
import { RegisterDataProvider } from './context/RegisterDataContext';
import AttemptsLimit from './components/attempt-limit-reach/AttemptsLimit';
import AdminLogin from './components/admin_login/AdminLogin';
import CustomerData from './components/customer-data/CustomerData';
import { ToastContainer } from 'react-toastify';
import SlotBooking from './components/time_slot/SlotBooking';
import VideoCall from './components/video_call/VideoCall';
import Success from './components/success/Success';

function App() {
  const location = useLocation();
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(true);
  const language = [
    {code: "en", name: "English"},
    {code: "hindi", name: "हिंदी"},
    {code: "marathi", name: "मराठी"}
  ];

  const isAdminLoginRoute = location.pathname === '/admin-login';

  return (
    <I18nextProvider i18n={i18next}>
      <div className='app'>
        <LanguageSwitcher language={language} />
        
        <RegisterDataProvider>
        <AdharDataProvider>
        <Routes>

{/*      
         {isAdminLoginRoute && (
                <Route path="/admin-login" element={<AdminLogin />} />
              )} */}

          <Route path='/' element={<Register />} />
          <Route path='/home' element={<Welcome />} />
          <Route path='/docupload' element={<DocsUpload />} />
          <Route path='/consent-check' element={<ConsentCheck />} />
          <Route path='/personal-details' element={<PersonalDetail />} />
          <Route path='/address' element={<SetAddress />} />
          <Route path='/add-nominee' element={<AddNominee />} />
          <Route path='/final-consents' element={<MitcConsent />} />
          <Route path='/attempt-limit-reached' element={<AttemptsLimit />} />
          <Route path='/time-slot' element={<SlotBooking />} />
          <Route path='/admin-login' element={<AdminLogin />} />

          

          {/* <Route path='/admin-login1' element={<AdminLogin />}></Route>
          {isUserAuthenticated && (
                <Route path='/admin-login' element={<AdminLogin />} />
          )} */}

          <Route path='/customer-data' element={<CustomerData />} />
          <Route path='/virtual-kyc' element={<VideoCall />} />
          <Route path='/success' element={<Success />} />

          
        <Route path="/*" lement={<Register />} />

        </Routes>
        </AdharDataProvider>
        </RegisterDataProvider>
        <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
            />
      </div>
    </I18nextProvider>
  );
}

export default App;
