import React, { useEffect, useState } from 'react';
import './register.css';
import { useTranslation } from 'react-i18next';
import googleIcon from './icons/google-svgrepo-com.svg'
import OtpModal from './OtpModal';
import axios from 'axios';
import API_URL from '../../utility/api';
import { handleApiPostRequest } from '../../utility/handleApiRequest';
import { GoogleLogin } from '@react-oauth/google';
import { Link, useNavigate, useParams } from 'react-router-dom';

function Register() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    
    const [contact, setContact] = useState('');
    const [isContactValid, setIsContactValid] = useState('');
    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState('');
    const [pincode, setPincode] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [showOtpModal, setShowOtpModal] = useState(false);
    const [otpData, setOtpData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
  



    const handleContactChange = (event) => {
        const input = event.target.value.replace(/\D/g, '');
        const formattedInput = formatPhoneNumber(input);
        const truncatedInput = input.slice(0, 10);
        const truncatedFormattedInput = formatPhoneNumber(truncatedInput);
        setContact(truncatedFormattedInput);
        setIsContactValid(validatePhone(truncatedInput));
    };

    const validatePhone = (phone) => {
        // Regular expression for phone number validation (10 digits)
        const regex = /^\d{10}$/;
        return regex.test(phone);
    };

    const formatPhoneNumber = (phoneNumber) => {
        // Format as XXXXX-XXXXX
        if (phoneNumber.length > 5) {
            return phoneNumber.slice(0, 5) + '-' + phoneNumber.slice(5, 10);
        } else {
            return phoneNumber;
        }
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        setIsEmailValid(validateEmail(event.target.value));
    };

    const validateEmail = (email) => {
        // Regular expression for basic email validation
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handlePincodeChange = (event) => {
        setPincode(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        try {
            const url = API_URL + '/Register/GenerateEmailAndPhoneOtp';
            const postData = {
                email: email,
                phonenumber: contact
            };
            const data = await handleApiPostRequest(url, postData);
            setOtpData(data);
            setShowOtpModal(true);
        } catch (error) {
            console.error('Error generating OTP:', error);
        } finally {
            setIsLoading(false);
        }
    };

    // const handleGoogleLogin = async (response) => {
    
    //     const { credential, clientId, select_by } = response;
    
    //     if (credential) {
    
    //         const decodedToken = parseJwt(credential);
    //         const email = decodedToken.email;
    //         const userData = {
    //             email,
    //         };
    
    //         try {
    //             const url = `${API_URL}/Register/GenerateEmailAndPhoneOtp`;
    //             const response = await axios.post(url, userData);
             
    //             setOtpData(response.data)
    //             setShowOtpModal(true)
   
    //         } catch (error) {
    //             console.error('Error sending user data to backend:', error);
    //         }
    //     } else {
    //         console.error('Invalid Google authentication response:', response);
    //     }
    // };
    
    const parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
            return null;
        }
    };

    return (
        <>
        <Link to='/admin-login'>
        <button className='admin-login-button'>Login</button>
        </Link>
            <div className='register-container'>
                <div className='register-form'>
                    <div className='form-container'>
                        {/* <div className='auth-holder'>
                            <div className='google_auth'>
                               
                                <GoogleLogin
                                    clientId={process.env.REACT_APP_GOOGLE_API} 
                                    onSuccess={handleGoogleLogin}
                                    onError={(error) => console.error('Google Login Error:', error)}
                                    />
                        
                            </div>
                        </div> */}
                        {/* <div className='option'>
                            <hr />
                            <p>{t('orEnterDetails')} </p>
                            <hr />
                        </div> */}
                        <form onSubmit={handleSubmit}>
                            <div className='form-field'>
                                <label htmlFor="contact">{t('contact')}:</label>
                                <span className='contact-field'><input type='text' className='countrycode' value={t('mobileNumberPrefix')} /> <input type="tel" id='contact' value={contact} onChange={handleContactChange} /> </span>
                            </div>
                            <div className='option-contact'>
                                <hr />
                                <p>{t('or')} </p>
                                <hr />
                            </div>
                            <div className='form-field'>
                                <label htmlFor="email">{t('email')}:</label>
                                <span className='contact-field'>
                                    <input type='text' id='email' placeholder={t('email')} value={email} onChange={handleEmailChange} /> </span>
                            </div>
                            <div className='form-field'>
                                <label htmlFor="pincode">{t('pincode')}:</label>
                                <span className='contact-field'><input type='text' id='pincode' placeholder={t('pincode')} value={pincode} onChange={handlePincodeChange} /> </span>
                                <p className='pincode-note'>{t('pincodeSuggestion')} </p>
                            </div>
                            <div className='agreement'>
                                {t('RegisterTermsAndCond')} <br />
                                <span>{t('TermsAndConditions')}</span> {t('AndLabel')} <span>{t('PrivacyPolicy')}</span>
                            </div>
                            <button className='btn' disabled={(isLoading) || (!isEmailValid && !isContactValid)}>
                                {isLoading ? t('Loading...') : t('Open')}
                            </button>
                    </form>
                </div>
                
            </div>
            <div className='register-text'>
                <div className='hero-text'>
                <h1>{t('herotext')}</h1>
                </div>
            </div>
        </div>
        {showOtpModal && <OtpModal contact={contact} email={email} otpData={otpData} />}
        </>
    );
}

export default Register;
