import React, { useState, useEffect } from 'react';
import './addnominee.css';
import Dropdown from '../dropdown/Dropdown';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useData } from '../../context/AdharDataContext';
import axios from 'axios';
import API_URL from '../../utility/api';

function AddNominee() {
  const { userData } = useData();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [nomineeName, setNomineeName] = useState('');
  const [selectedRelationship, setSelectedRelationship] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [showAddressFields, setShowAddressFields] = useState(false);
  const [buildingBlock, setBuildingBlock] = useState('');
  const [lane1, setLane1] = useState('');
  const [lane2, setLane2] = useState('');
  const [landmark, setLandmark] = useState('');
  const [pincode, setPincode] = useState('');
  const [relationshipOptions, setRelationshipOptions] = useState([]);

  useEffect(() => {
    const fetchRelationshipOptions = async () => {
      try {
        const response = await axios.get(API_URL + '/MasterData/GetNomineeTypeList?langkey=en');
        setRelationshipOptions(response.data);
      } catch (error) {
        console.error('Error fetching relationship options:', error);
      }
    };

    fetchRelationshipOptions();
  }, []);

  const validateName = (name) => {
    const regex = /^[A-Za-z\s]*$/;
    return regex.test(name);
  };

  const handleNomineeNameChange = (e) => {
    const { value } = e.target;
    if (validateName(value)) {
      setNomineeName(value);
    }
  };

  const handleRelationshipSelect = (relationship) => {
    setSelectedRelationship(relationship);
  };

  const handleDateOfBirthChange = (e) => {
    const today = new Date().toISOString().slice(0, 10);
    e.target.setAttribute('max', today);

    if (e.target.value <= today) {
      setDateOfBirth(e.target.value);
    }
  };

  const handleAddressToggle = () => {
    setShowAddressFields(!showAddressFields);
  };

  const handleBuildingBlockChange = (e) => {
    setBuildingBlock(e.target.value);
  };

  const handleLane1Change = (e) => {
    setLane1(e.target.value);
  };

  const handleLane2Change = (e) => {
    setLane2(e.target.value);
  };

  const handleLandmarkChange = (e) => {
    setLandmark(e.target.value);
  };

  const handlePincodeChange = (e) => {
    setPincode(e.target.value);
  };

  const isFormValid = () => {
    if (nomineeName && selectedRelationship && dateOfBirth) {
      if (!showAddressFields) {
        if (buildingBlock && lane1 && lane2 && landmark && pincode) {
          return true;
        }
      } else {
        return true;
      }
    }
    return false;
  };

  const handleProceed = async (e) => {
    e.preventDefault();
    navigate('/final-consents');

    const nomineePayload = {
      customerId: userData.customerId,
      nominee: {
        firstName: nomineeName.split(' ')[0],
        lastName: nomineeName.split(' ')[1],
        dateOfBirth: dateOfBirth,
        phoneNumber: '7879846546', 
        active: true,
        nomineeTypeId: selectedRelationship.value 
      }
    };

    try {
      const response = await axios.post(API_URL + '/Customer/UpdateCustomerNominee', nomineePayload);
      console.log('Nominee updated successfully:', response.data);
      navigate('/final-consents');
    } catch (error) {
      console.error('Error updating nominee:', error);
    }
  };

  const handleSkip = () => {
    navigate('/final-consents');
  };

  return (
    <div className='nominee-container'>
      <div className='nominee-holder'>
        <p className='skip-txt' onClick={handleSkip}>{t('skip')}</p>
        <h3>{t('addNominee')}</h3>
        <p>{t('nomineeClaimsTheProceedsYour')} <br/> {t('accountAfterYourDemise')}</p>
        <div className='nominee-form'>
          <form>
            <div className="form-field">
              <div className='input-field'>
                <input
                  type="text"
                  id="nominee"
                  value={nomineeName}
                  placeholder={t('enterNomineesFullName')}
                  onChange={handleNomineeNameChange}
                  maxLength={50} 
                />
              </div>
            </div>
            
            <Dropdown
              title={t('selectRelationship')}
              options={relationshipOptions.map((option) => ({
                label: option.typeName,
                value: option.nomineeTypeId
              }))}
              selectedOption={selectedRelationship}
              onSelect={handleRelationshipSelect}
            />

            <div className="form-field">
              <div className='input-field'>
                <input
                  type="date"
                  id="DOB"
                  value={dateOfBirth}
                  placeholder="Date Of Birth"
                  onChange={handleDateOfBirthChange}
                  maxLength={50} 
                />
              </div>
            </div>
            
            <h3 className='nominee-address'>{t('nomineeSAddress')}</h3>
            <div className='nominee-address-toggle'>
              <p>{t('sameAsYourAddress')}</p>
              <label className="switch">
                <input
                  type="checkbox"
                  id="addressToggle"
                  checked={showAddressFields}
                  onChange={handleAddressToggle}
                />
                <span className="slider round"></span>
              </label>
            </div>

            {!showAddressFields && (
              <>
                <div className="form-field">
                  <div className='input-field'>
                    <input
                      type="text"
                      id="building-block"
                      placeholder={t('buildingBlock')}
                      value={buildingBlock}
                      onChange={handleBuildingBlockChange}
                      maxLength={50} 
                    />
                  </div>
                </div>

                <div className="form-field">
                  <div className='input-field'>
                    <input
                      type="text"
                      id="lane1"
                      placeholder={t('lane1')}
                      value={lane1}
                      onChange={handleLane1Change}
                      maxLength={50} 
                    />
                  </div>
                </div>

                <div className="form-field">
                  <div className='input-field'>
                    <input
                      type="text"
                      id="lane2"
                      placeholder={t('lane2')}
                      value={lane2}
                      onChange={handleLane2Change}
                      maxLength={50} 
                    />
                  </div>
                </div>

                <div className="form-field">
                  <div className='input-field'>
                    <input
                      type="text"
                      id="landmark"
                      placeholder={t('landmark')}
                      value={landmark}
                      onChange={handleLandmarkChange}
                      maxLength={50} 
                    />
                  </div>
                </div>

                <div className="form-field">
                  <div className='input-field'>
                    <input
                      type="text"
                      id="pincode"
                      placeholder={t('pincode')}
                      value={pincode}
                      onChange={handlePincodeChange}
                      maxLength={6} 
                    />
                  </div>
                </div>
              </>
            )}
            <div className='nominee-btn'>
              <button className='proceed-button' onClick={handleProceed} disabled={!isFormValid()}>{t('proceed')}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddNominee;
