import React, { useEffect, useState } from 'react'
import './welcome.css'
import AdharVerify from './adharOtpModal/AdharVerify'
import { AiFillSafetyCertificate } from "react-icons/ai";
import verifiedIcon from '../../assets/images/check-circle-svgrepo-com.svg'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import API_URL from '../../utility/api'; 
import { useData } from '../../context/AdharDataContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Welcome() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { userData, setUserData, setAdharData } = useData();
    const [showAdharVerify, setShowAdharVerify] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [panValue, setPanValue] = useState('');
    const [adhaarValue, setAdhaarValue] = useState('');
    const [checkbox1Checked, setCheckbox1Checked] = useState(false);
    const [checkbox2Checked, setCheckbox2Checked] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [isPANVerified, setIsPANVerified] = useState(false);
    const [isAadharInvalid, setIsAadharInvalid] = useState(false);
    const [isAadhaarEdited, setIsAadhaarEdited] = useState(false);
    const [aadharVerified, setAadharVerified] = useState(false);
    const [otpData, setOtpData] = useState(null);
    const [responseFromAPI, setResponseFromAPI] = useState(null);

    const handleAPIResponse = (responseData) => {
        setResponseFromAPI(responseData);
    };
    console.log(responseFromAPI)
    const adharno = adhaarValue.replace(/-/g, '')

    function handleVerifyClick() {
        const adharno = adhaarValue.replace(/-/g, '')
        axios.post(API_URL + '/AadharService/generateaadharotp?aadharno='+ adharno)
            .then(response => {
                
                if (response.status === 200) {
                    setOtpData(response.data)
                    setIsAadharInvalid(false);
                    setShowAdharVerify(true);
                } else {
                    toast.error("Failed to verify Aadhaar number");
                    throw new Error('Failed to verify Aadhaar number');
                }
            })
            .catch(error => {
                console.log("Response ====>", error.response.data);
                toast.error(error.response.data);
                setIsAadharInvalid(true);
                console.error('Error verifying Aadhaar number:', error);
            });
    }

    const handlePanChange = (event) => {
        const input = event.target.value.toUpperCase();
        setPanValue(input);
    
        // Regular expression to validate PAN card number
        const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
        if (panRegex.test(input)) {
            setIsPANVerified(true);
        } else {
            setIsPANVerified(false);
        }
      };

    const handleAadhaarVerificationStatus = (status) => {
        setAadharVerified(status);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Backspace') {
            const index = event.target.selectionStart;
            if (index % 5 === 0) {
                event.target.setSelectionRange(index - 1, index - 1);
            }
        }
    };

    const handleAadhaarChange = (e) => {
        const inputValue = e.target.value;
        const sanitizedValue = inputValue.replace(/[^0-9]/g, ''); 
        const formattedValue = sanitizedValue.replace(/(.{4})/g, "$1-"); 
        const trimmedValue = formattedValue.slice(0, 14);
        setAdhaarValue(trimmedValue);
        setIsAadhaarEdited(true);
        setAadharVerified(false); 
        setIsAadharInvalid(false);
    };

    const handleProceed = (e) => {
        e.preventDefault();
        const payload = {
            firstName: responseFromAPI.data.full_name,
            dateOfBirth: responseFromAPI.data.dob,
            phoneNumber: '8989866536',
            panNumber: panValue.toUpperCase(),
            aadharNumber: responseFromAPI.data.aadhaar_number,
            isVideoKycDone: false,
            active: true,
            deleted: false
        };

        const newCustomerEndPoint = '/Customer/createnewcustomer/';
        axios.post(API_URL + newCustomerEndPoint , payload)
            .then(response => {
                console.log('Response from API:', response.data);
                setUserData(response.data);
                setAdharData(responseFromAPI);
                sessionStorage.setItem('responseData', JSON.stringify(response.data));
                navigate('/docupload');
            })
            .catch(error => {
                console.error('Error creating new customer:', error);
            });
    };
    
    useEffect(() => {
        if (panValue && adhaarValue && checkbox1Checked && checkbox2Checked && isPANVerified && aadharVerified) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }, [panValue, adhaarValue, checkbox1Checked, checkbox2Checked, aadharVerified]);

    useEffect(() => {
        if (adhaarValue.length !== 14) {
            setIsVerified(false);
        }
    }, [adhaarValue]);

    useEffect(() => {
        if (isVerified && isAadhaarEdited) {
            setIsVerified(false);
        }
    }, [isVerified, isAadhaarEdited]);

    return (
        <div className='welcome-container'>
            <div className='welcome-holder'>
            <h2>{t('welcome')} 👋</h2>
            <p>{t('pleaseProvideYourDetails')}</p>
                <form>
                    <div className='form-field'>
                        <input
                            type='text'
                            placeholder={t('panNumber')}
                            value={panValue.toUpperCase()}
                            onChange={handlePanChange}
                            maxLength={10}
                        />
                        {isPANVerified ? (
                          <p style={{ color: 'green', whiteSpace: "nowrap" }}>Valid PAN</p>
                        ) : (
                          <p style={{ color: 'red', whiteSpace: "nowrap" }}>Invalid PAN</p>
                        )}
                    </div>
                    <div className='form-field'>
                        <input
                            type='text'
                            placeholder={t('aadhaarNumber')}
                            value={adhaarValue}
                            onChange={handleAadhaarChange}
                            onKeyDown={handleKeyDown}
                            maxLength={14}
                        />
                        {isAadharInvalid && (
                          <p style={{ color: 'red', whiteSpace: "nowrap" }}>Invalid Aadhar</p>
                        ) }
                        {adhaarValue.length === 14 && !aadharVerified && !isAadharInvalid &&(
                            <p className='verify-text' onClick={handleVerifyClick}>
                                {t('verify')}
                            </p>
                        )}
                        {aadharVerified && (
                            <img src={verifiedIcon} alt='Verified' className='verify-icon' />
                        )}
                    </div>

                    <div className='checkbox-field'>
                        <input
                            type='checkbox'
                            checked={checkbox1Checked}
                            onChange={(e) => setCheckbox1Checked(e.target.checked)}
                        />
                        <p className='consent-para'>{t('aadhaarConsent')}</p>
                    </div>
                    <div className='checkbox-field'>
                        <input
                            type='checkbox'
                            checked={checkbox2Checked}
                            onChange={(e) => setCheckbox2Checked(e.target.checked)}
                        />
                        <p className='consent-para'>{t('bankAllowCheckCibilScore')}</p>
                    </div>
                    <span className='safety-note'><AiFillSafetyCertificate/>{t('yourDataIsSafeWithUs')}</span>
                    <button className='welcome-btn' disabled={buttonDisabled} onClick={handleProceed}>{t('proceedToDocuments')}</button>
                </form>
            </div>
            {showAdharVerify && <AdharVerify 
            handleAPIResponse={handleAPIResponse} 
            handleVerifyClick={handleVerifyClick} 
            otpData={otpData.data} 
            adhaarNumber={adhaarValue.replace(/-/g, '')} 
            updateAadharVerificationStatus={handleAadhaarVerificationStatus} 
            setShowAdharVerify={setShowAdharVerify} 
            />}
        </div>
    )
}

export default Welcome;
