import React from 'react'
import './success.css'
import check from './check-svgrepo-com (4).svg'

function Success() {
  return (
    <div className='success-container'>
        <div className='success-holder'>
            <div className='top'>
                <img src={check} alt='check-img'/>
                <p>SUCCESS</p>
            </div>
            <div className='bottom'>
                <h3>Your request for Video KYC has been successfully Registered.</h3>
            </div>
        </div>
    </div>
  )
}

export default Success